// Bootstrap Framework

import jquery from 'jquery';
window.$ = window.jQuery = jquery;//Global
import "./scss/index.scss";
import "popper.js";
import "bootstrap";
import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import 'jquery-match-height';

//#region hover navbar dropdowns
const $dropdown = $('#navbarNav .dropdown');
const $dropdownToggle = $('#navbarNav .dropdown-toggle');
const $dropdownMenu = $('#navbarNav .dropdown-menu');
const showClass = 'show';

$(window).on('load resize', function() {
  if (this.matchMedia('(min-width: 992px)').matches) {
    $dropdown.hover(
      function() {
        const $this = $(this);
        $this.addClass(showClass);
        $this.find($dropdownToggle).attr('aria-expanded', 'true');
        $this.find($dropdownMenu).addClass(showClass);
      },
      function() {
        const $this = $(this);
        $this.removeClass(showClass);
        $this.find($dropdownToggle).attr('aria-expanded', 'false');
        $this.find($dropdownMenu).removeClass(showClass);
      }
    );
    $dropdown.click(function() {
      const $this = $(this);
      location.href = $this.find($dropdownToggle).attr('href');
    });
  } else {
    $dropdown.off('mouseenter mouseleave');
  }
});
//#endregion

$(document).ready(function () {
  //$(".btn-search").click(function (e) {
  //  e.preventDefault();
  //  $(".input-search").toggleClass("d-none");
  //});
});

$(".slider").slick({
  nextArrow: ".next",
  prevArrow: ".previous",
  fade: true,
  dots: true
});

$(".slider-news").slick({
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows:true,
  nextArrow: ".next-news",
  prevArrow: ".previous-news",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
});



$('.slider-interno').slick({
    dots: true,
    arrows: false
});

